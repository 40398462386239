import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

function Footer() {
  return (
    <div className={styles['footer-container']}>
      <section className={styles['footer-subscription']}>
        <p className={styles['footer-subscription-heading']} style={{ fontFamily: 'Futura, sans-serif' }}>
          Contact Us
        </p>
        <p className={styles['footer-subscription-text']}>
          contact@policypulse.institute
        </p>
      </section>
      <section className={styles['social-media']}>
        <div className={styles['social-media-wrap']}>
          <div className={styles['footer-logo']}>
            <Link to='/' className={styles['social-logo']} style={{ fontFamily: 'Futura, sans-serif' }}>
              POLICY PULSE
            </Link>
          </div>
          <small className={styles['website-rights']} style={{ fontFamily: 'Futura, sans-serif' }}>POLICY PULSE © 2023 All rights reserved.</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;