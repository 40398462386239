export const cardExpertise = {
  image: 'images/bkg-dotted.svg'
}

export const cardPolicy = {
  image: 'images/bkg-farm.png',
  title: 'Policy Expertise',
  subtitle: 'POLICY CRAFTING & EVALUATION',
  par1: 'We offer a knowledge-based approach to policy development, partnering with organizations and governments to craft innovative and evidence-based solutions.',
  par2: 'We leverage cutting-edge research insights and academic rigor to help policymakers design implementable and impactful policies.',
  par3: 'Our approach integrates robust policy evidence with an in-depth understanding of local contexts, while carefully accounting for social, political and financial risks. We support policymakers in articulating these dimensions to ensure sound, cost-effective, policy delivery in complex environments.'
}

export const cardPolitics= {
  image: 'images/bkg-meet3.png',
  title: 'Strategic Advisory',
  subtitle: 'PUBLIC AFFAIRS & STRATEGY',
  par1: 'Our strategic advisory team guides governments and organisations through their complex and dynamic operating environments.',
  par2: 'We use a blend of knowledge, data, and discernment to develop sound public strategies to navigate political shifts, technological advancements, and evolving public sentiment.',
  par3: "At Policy Pulse, we empower leaders to execute their policies effectively. Recognizing that execution is today's leading challenge, we provide strategic advice, policy recommendations, and thoughtful implementation support throughout the delivery process."
}

export const cardData = {
  image: 'images/bkg-data.png',
  title: 'Data Analytics',
  subtitle: 'QUANTITATIVE TOOLS FOR EFFECTIVE DECISION-MAKING',
  par1: 'We employ advanced quantitative techniques to analyze large and complex datasets, translating key findings into data visualizations that equip policymakers with actionable insights.',
  par2: 'Policy Pulse brings together public policy and data analytics experts who use knowledge to interpret data and develop nuanced, effective policy recommendations.',
  par3: 'Our team routinely works with diverse data sources — from population surveys to financial data — helping organizations create innovative analytical tools and conduct accurate policy monitoring and evaluation.'
}