import React, { useRef, useEffect, useState } from 'react';
import styles from './HeroSection.module.css';

function HeroSection() {
  const videoRef = useRef(null);
  const [revealComplete, setRevealComplete] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    
    if (video) {
      const playVideo = () => {
        video.play().catch(error => {
          console.warn("Error playing video:", error);
          // You can add fallback behavior here if needed
        });
      };
  
      // Attempt to play the video
      playVideo();
  
      // Add event listener for when the video can play through
      video.addEventListener('canplaythrough', playVideo);
  
      const timer = setTimeout(() => {
        setRevealComplete(true);
      }, 3500);
  
      return () => {
        clearTimeout(timer);
        if (video) {
          video.removeEventListener('canplaythrough', playVideo);
        }
      };
    }
  }, []);

  return (
    <div className={styles['hero-container']}>
      <video
        ref={videoRef}
        loop
        muted
        playsInline
        preload="auto"
        src="/videos/home1_720p.mp4"
      />
      <h1>
        <span className={`${styles['line']} ${styles['line1']}`}>Knowledge and</span>
        <span className={`${styles['line']} ${styles['line2']}`}>Data Analytics</span>
        <span className={`${styles['line']} ${styles['line3']}`}>for Policy</span>
      </h1>
      <div className={styles['hero-inner']}>
        <h2 className={revealComplete ? styles['visible'] : ''}>
          Blending academic insight and policy expertise to equip leaders and organisations with innovative, evidence-based solutions.
        </h2>
      </div>
    </div>
  );
}

export default HeroSection;