import React from 'react';
import PropTypes from 'prop-types';
import styles from './SplitComponent.module.css';
import '../styles/theme.css';

function SplitComponent({ title, subtitle, par1, par2, par3, image }) {

  return (
    <div className={styles['split-component-container']}>
      <div className={styles['text-section']}>
        <div className={styles['split-title']}>{title}</div>
        <div className={styles['split-subtitle']}>{subtitle}</div>
        <p className={styles['split-text']}>{par1}</p>
        <p className={styles['split-text']}>{par2}</p>
        <p className={styles['split-text']}>{par3}</p>
      </div>
      <div className={styles['image-section']}>
        <img src={image} alt="Descriptive alt text" />
      </div>
    </div>
  );
}

SplitComponent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  par1: PropTypes.string.isRequired,
  par2: PropTypes.string.isRequired,
  par3: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default SplitComponent;