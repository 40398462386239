import React from 'react';
import styles from './MottoComponent.module.css';

function MottoComponent() {

  return (
    <div className={styles['motto-container']}>
      <p className={styles['motto-text']}>Primum Non Desipere</p>
      <p className={styles['motto-explanation']}> 
        Our motto means 
        <span className={styles['motto-highlighted']}> "First, Do Not Act Foolishly" </span> 
        <br />
        It conveys our commitment to prioritizing wisdom and discernment in our advice and recommendations, reflecting our pursuit of excellence and thoughtful consideration in all we do.
      </p>
    </div>
  );
}

export default MottoComponent;