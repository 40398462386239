import React from 'react';
import styles from './SummaryComponent.module.css';

function SummaryComponent() {
  return (
    <div className={styles['outer-container']}>
      <div className={styles['summary-container']}>
        <p className={styles['summary-text']}>
          We combine academic rigour, practical expertise, and strategic vision 
          <br/> to craft transformative solutions to research and policy challenges.
        </p>
      </div>
    </div>
  );
}

export default SummaryComponent;