import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import styles from './App.module.css';
import Home from './components/pages/Home';
import Team from './components/pages/Team/Team';
import Contact from './components/pages/Contact/Contact';
import About from './components/pages/About';
import BackgroundComponent from './components/Background';
import MottoComponent from './components/MottoComponent'; 
import ScrollToTop from './components/ScrollTop';
import { usePageTracking } from './hooks/usePageTracking';

function AppContent() {
  usePageTracking();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const videoElement = document.createElement('video');
    videoElement.src = '/videos/home1.mp4';
    videoElement.preload = 'auto';
    
    let isMounted = true;

    const handleLoaded = () => {
      if (isMounted) {
        setIsLoading(false);
        setTimeout(() => {
          const initialLoader = document.getElementById('initial-loader');
          if (initialLoader) {
            initialLoader.style.opacity = '0';
            setTimeout(() => {
              initialLoader.style.display = 'none';
            }, 500); // Match this to your CSS transition time
          }
        }, 100); // Small delay to ensure React has updated the DOM
      }
    };

    const handleCanPlayThrough = () => {
      handleLoaded();
    };

    videoElement.addEventListener('canplaythrough', handleCanPlayThrough);

    // Start loading the video
    videoElement.load();

    // Fallback in case the video takes too long or fails to load
    const timeout = setTimeout(() => {
      handleLoaded();
    }, 5000); // Reduced timeout to 5 seconds

    return () => {
      isMounted = false;
      videoElement.removeEventListener('canplaythrough', handleCanPlayThrough);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={`${styles['app-container']} ${isLoading ? styles['loading'] : styles['loaded']}`}>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/background" element={<BackgroundComponent />} />
        <Route path="/motto" element={<MottoComponent />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;