import React, { useState, useEffect, useRef } from 'react';
import styles from './Home.module.css';
import '../../App.module.css';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import BackgroundComponent from '../Background';
import MottoComponent from '../MottoComponent';
import SplitComponent from '../SplitComponent';
import SplitComponentReverse from '../SplitComponentReverse';
import SummaryComponent from '../SummaryComponent'; 
import TransitionComponent from '../TransitionComponent'; 

import { cardExpertise, cardData, cardPolicy, cardPolitics } from './Data';

function Home() {
  const [visibleSections, setVisibleSections] = useState({});
  const sectionRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setVisibleSections((prev) => ({
            ...prev,
            [entry.target.id]: entry.isIntersecting,
          }));
        });
      },
      { threshold: 0, rootMargin: "0px 0px 0px 0px" }
    );

    // Capture the current value of sectionRefs.current
    const currentRefs = sectionRefs.current;

    currentRefs.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      // Use the captured value in the cleanup function
      currentRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <div>
      <HeroSection />
      <BackgroundComponent {...cardExpertise}/>
      <SummaryComponent /> 
      <TransitionComponent /> 
      <div className={styles['container']}>
        <div 
          id="policy-section"
          ref={(el) => (sectionRefs.current[0] = el)}
          className={`${styles['animated-section']} ${visibleSections['policy-section'] ? styles['visible'] : ''}`}
        >
          <div className={styles['component-wrapper']}>
            <div className={styles['component-content']}>
              <div className={styles['staggered-element']} style={{"--stagger-delay": "100ms"}}>
                <SplitComponent {...cardPolicy}/>
              </div>
            </div>
          </div>
        </div>
        <div 
          id="data-section"
          ref={(el) => (sectionRefs.current[1] = el)}
          className={`${styles['animated-section']} ${visibleSections['data-section'] ? styles['visible'] : ''}`}
        >
          <div className={styles['component-wrapper']}>
            <div className={styles['component-content']}>
              <div className={styles['staggered-element']} style={{"--stagger-delay": "100ms"}}>
                <SplitComponentReverse {...cardData}/>
              </div>
            </div>
          </div>
        </div>
        <div 
          id="politics-section"
          ref={(el) => (sectionRefs.current[2] = el)}
          className={`${styles['animated-section']} ${visibleSections['politics-section'] ? styles['visible'] : ''}`}
        >
          <div className={styles['component-wrapper']}>
            <div className={styles['component-content']}>
              <div className={styles['staggered-element']} style={{"--stagger-delay": "100ms"}}>
                <SplitComponent {...cardPolitics}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MottoComponent /> 
      <Footer />
    </div>
  );
}

export default Home;