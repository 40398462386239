import React from 'react';
import PropTypes from 'prop-types';
import styles from './PartnersComponent.module.css';

function PartnersComponent({ images }) {
  console.log('PartnersComponent styles:', styles); // For debugging

  return (
    <div className={styles['image-container']}>
      <div className={styles['image-title']}>Our Team Has Worked With</div>
      <div className={styles['image-grid']}>
        {images.map((imageUrl, index) => (
          <div className={styles['image-item']} key={index}>
            <img src={imageUrl} alt={`Partner: ${index + 1}`} className={styles['single-image']} />
          </div>
        ))}
      </div>
    </div>
  );
}

PartnersComponent.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PartnersComponent;