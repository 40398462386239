import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';
import OwlImage from './bkg-owl.png';

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <nav className={styles['navbar']}>
      <div className={styles['navbar-container']}>
        <div className={styles['navbar-logo-container']} onClick={closeMobileMenu}>
          <img src={OwlImage} alt="Owl Logo" className={styles['navbar-logo-image']} />
          <div className={styles['navbar-text-container']}>
            <Link to='/' className={styles['navbar-logo']}>
              Policy Pulse Institute
            </Link>
            <div className={styles['navbar-motto']}>
              Primum Non Desipere
            </div>
          </div>
        </div>
        <div className={styles['menu-icon']} onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? `${styles['nav-menu']} ${styles['active']}` : styles['nav-menu']}>
          <li className={styles['nav-item']}>
            <Link to='/' className={styles['nav-links']} onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className={styles['nav-item']}>
            <Link to='/team' className={styles['nav-links']} onClick={closeMobileMenu}>
              Our Team
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;