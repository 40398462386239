import React from 'react';
import PropTypes from 'prop-types';
import styles from './TeamComponent.module.css';

function TeamComponent({ members }) {

  return (
    <div className={styles['team-container']}>
      <div className={styles['team-title']}>Our Core Team</div>
      <div className={styles['team-grid']}>
        {members.map((member, index) => (
          <div className={styles['team-member']} key={index}>
            <div className={styles['team-photo-container']}>
              <img src={member.photoUrl} alt={member.name} className={styles['team-photo']} />
            </div>
            <div className={styles['team-info']}>
              <div className={styles['team-name']}>{member.name}</div>
              <div className={styles['team-position']}>{member.position}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

TeamComponent.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      photoUrl: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TeamComponent;