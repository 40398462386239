import React from 'react';
import styles from './TransitionComponent.module.css';

function TransitionComponent() {
  return (
    <div className={styles['outer-container']}>
      <div className={styles['transition-container']}>
        <p className={styles['transition-text']}>
          
        </p>
      </div>
    </div>
  );
}

export default TransitionComponent;